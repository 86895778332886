import { APP_CLINIC, REDIRECT_PARAM } from "core/consts";
import { getQueryVariable } from "core/model/utils/urls";
import MagicLogin from "dsl/atoms/MagicLogin";
import LoginPage from "dsl/organisms/LoginPage";
import { Navigate, useLocation } from "react-router-dom";
import { useIsLoggedOnClinicApp } from "reduxentities/selectors";

export function CareseekerAuthPage() {
  const location = useLocation();
  const logged = useIsLoggedOnClinicApp();

  return logged === "ok" ? (
    <Navigate
      to={getQueryVariable(location.search, REDIRECT_PARAM) || "/app"}
    />
  ) : (
    <>
      <MagicLogin
        username={getQueryVariable(location.search, "username")}
        password={getQueryVariable(location.search, "password")}
      />
      <LoginPage app={APP_CLINIC} />
    </>
  );
}
