import {
  DatePickerWithLabel,
  Section,
  SectionRow,
  SMALL_INPUT_MIN_WIDTH,
  SMALL_INPUT_WIDTH,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";

export const SectionD = () => {
  const translations = useTranslations();
  return (
    <Section
      title={translations.patientForms.transitionalCareForm.sectionD.title}
    >
      <SectionRow>
        <DatePickerWithLabel
          bold
          label={
            translations.patientForms.transitionalCareForm.sectionD
              .dateFieldLabel
          }
          elementName="insurance_form_sent_date"
          wrapperSx={{ width: "100%" }}
          inputSx={{
            width: SMALL_INPUT_WIDTH,
            minWidth: SMALL_INPUT_MIN_WIDTH,
          }}
        />
      </SectionRow>
    </Section>
  );
};
