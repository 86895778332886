import { useGetTransitionalCare } from "apollo/hooks/queries";
import {
  AUCTION_STATUS_NOT_STARTED,
  QUERY_PROGRESS_SUCCEED,
  SEARCH_ACTION_GENERATE_TRANSITIONAL_CARE_FORM,
} from "core/consts";
import { getSearchActions } from "core/model/auctions";
import { Auction, Patient, SearchAction, TransitionalCare } from "core/types";
import { useOnEnter } from "dsl/atoms/Routes";
import PatientNavigation from "dsl/organisms/PatientNavigation";
import { TransitionalCareForm } from "./Form";
import { FormWrapper } from "./FormWrapper";
import { FormExplanationHeader } from "./Header";
import { FormSearchNotStarted } from "./SearchNotStarted";

export const getTransitionalCareAction = (
  auction: Auction,
): SearchAction | null => {
  const {
    [SEARCH_ACTION_GENERATE_TRANSITIONAL_CARE_FORM]: transitionalCareAction,
  } = getSearchActions(auction);

  return transitionalCareAction;
};

export const transitionalCareAvailable = (auction: Auction): boolean => {
  const action = getTransitionalCareAction(auction);

  return !!(action && !action.context?.disabled);
};

export const TransitionalCarePresenter = ({
  auction,
  transitionalCare,
}: {
  auction: Auction;
  transitionalCare: Readonly<TransitionalCare> | undefined;
}) => {
  if (auction.status === AUCTION_STATUS_NOT_STARTED)
    return (
      <FormWrapper
        transitionalCare={transitionalCare}
        auctionStatus={auction.status}
      >
        <FormSearchNotStarted />
      </FormWrapper>
    );

  return (
    <FormWrapper
      transitionalCare={transitionalCare}
      auctionStatus={auction.status}
    >
      <FormExplanationHeader transitionalCare={transitionalCare} />
      <TransitionalCareForm
        auction={auction}
        transitionalCare={transitionalCare}
      />
    </FormWrapper>
  );
};

export default function PatientTransitionalCare({
  auction,
  patient,
}: {
  auction: Auction;
  patient: Patient;
}) {
  useOnEnter();
  const [queryProgress, transitionalCare] = useGetTransitionalCare({
    auctionId: auction.id,
    patientId: patient.id,
  });

  if (queryProgress !== QUERY_PROGRESS_SUCCEED) return null;

  return (
    <PatientNavigation patient={patient} auction={auction}>
      <TransitionalCarePresenter
        auction={auction}
        transitionalCare={transitionalCare}
      />
    </PatientNavigation>
  );
}
