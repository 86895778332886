import { VerticalLayout } from "ds_legacy/materials/layouts";
import { padding } from "ds_legacy/materials/metrics";
import {
  DatePickerWithLabel,
  Footnote,
  FootnoteDescription,
  FootnoteLabel,
  GAP,
  Section,
  SectionRow,
  TextInputWithLabel,
} from "dsl/ecosystems/PatientForms/components";
import { useTranslations } from "translations";
import Translations from "translations/types";

const FOOTNOTES: Record<"one" | "two", Footnote> = {
  one: {
    superText: 1,
    description: (translations: Translations) =>
      translations.patientForms.transitionalCareForm.hospitalStay.footnoteOne,
    label: (translations: Translations) =>
      translations.patientForms.transitionalCareForm.hospitalStay.locationId,
  },
  two: {
    superText: 2,
    description: (translations: Translations) =>
      translations.patientForms.transitionalCareForm.hospitalStay.footnoteTwo,
    label: (translations: Translations) =>
      translations.patientForms.transitionalCareForm.hospitalStay
        .departmentCode,
  },
};

export const TransitionalCareSection = () => {
  const translations = useTranslations();
  return (
    <Section
      title={
        translations.patientForms.transitionalCareForm.hospitalStay
          .titleTransitionalCare
      }
    >
      <SectionRow>
        <DatePickerWithLabel
          bold
          label={
            translations.patientForms.transitionalCareForm.hospitalStay
              .startDateTransitionalCare
          }
          elementName="transitional_care_start_date"
        />
        <DatePickerWithLabel
          bold
          label={
            translations.patientForms.transitionalCareForm.hospitalStay
              .endDateTransitionalCare
          }
          elementName="transitional_care_end_date"
        />
        <TextInputWithLabel
          bold
          label={<FootnoteLabel footnote={FOOTNOTES.one} />}
          elementName="transitional_care_location_id"
          large
        />
        <TextInputWithLabel
          bold
          label={<FootnoteLabel footnote={FOOTNOTES.two} />}
          elementName="transitional_care_department_code"
        />
      </SectionRow>
      <VerticalLayout
        style={{
          padding: padding(GAP, 0, 0),
          boxSizing: "border-box",
        }}
      >
        <FootnoteDescription footnote={FOOTNOTES.one} />
        <FootnoteDescription footnote={FOOTNOTES.two} />
      </VerticalLayout>
    </Section>
  );
};
