import { useGetAuction, useGetPatientQuery } from "apollo/hooks/queries";
import { Result } from "apollo/utils";
import { PATIENT_TAB_SEARCH } from "core/consts";
import { useIntParams } from "core/model/utils/urls";
import { Auction, AuctionNoPatient, Patient } from "core/types";
import { BaseEventSchema } from "core/validationSchemas/tracking";
import { PatientContext } from "dsl/atoms/Contexts";
import { RedirectButton } from "dsl/atoms/RedirectButton";
import { usePageMetaData } from "dsl/hooks";
import { PatientEncryptionProvider } from "dsl/organisms/Encryption/Patient";
import { useNotification } from "dsl/organisms/NotificationProvider";
import { PatientMenuStatic } from "dsl/organisms/PatientMenu";
import { ReactNode, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Outlet,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import { useTracking } from "react-tracking";
import {
  selectCareseekerRoles,
  useLoggedInAccount,
} from "reduxentities/selectors";
import { useConnecterWithCareseeker } from "reduxentities/selectors/hooks";
import { useTranslations } from "translations";
import ErrorPage from "../ErrorPage";

type PatientHomeContext = {
  auction: Auction;
  patient: Patient;
};

export function usePatientHomeContext() {
  return useOutletContext<PatientHomeContext>();
}

function usePatientPageTitle(path: string, patient: Patient | undefined) {
  const translations = useTranslations();

  const patientIdentifier = patient?.user_id;
  const getNewTitle = useCallback(
    (titleBase: string) =>
      `${translations.patient.patient} ${
        patientIdentifier ? `${patientIdentifier}` : ""
      }${titleBase ? ` ${titleBase}` : ""}`,
    [patientIdentifier],
  );

  const pageMap: { [key: string]: string } = {
    search: translations.search.search,
    profile: translations.patient.profile,
    timeline: translations.timeline.title,
  };

  const pageKey = Object.keys(pageMap).find((key) => path.includes(key));

  usePageMetaData({
    title: () => getNewTitle((pageKey && pageMap[pageKey]) || ""),
  });
}

export function PatientHomeMenu({ auction }: { auction?: Auction }) {
  if (!auction) return null;

  return <PatientMenuStatic auction={auction} />;
}

function PatientHomeContext({
  auction,
  children,
  patient,
}: {
  auction: AuctionNoPatient | undefined;
  children: ReactNode;
  patient: Patient | undefined;
}) {
  const { Track } = useTracking<DeepPartial<BaseEventSchema>>({
    user_information: { auction_id: auction?.id },
  });

  return (
    <Track>
      <PatientEncryptionProvider patient={patient}>
        <PatientContext.Provider
          value={{
            patientType: auction?.search_type,
            auctionId: auction?.id,
            patientId: patient?.id,
          }}
        >
          {children}
        </PatientContext.Provider>
      </PatientEncryptionProvider>
    </Track>
  );
}

function PatientHome({
  auctionId,
  patient,
}: {
  auctionId: number;
  patient: Patient;
}) {
  const [queryProgress, auction] = useGetAuction(
    auctionId ?? patient.auctions?.[0].id,
  );

  usePatientPageTitle(location.pathname, auction?.patient);
  const translations = useTranslations();
  const { careseekerId } = useConnecterWithCareseeker();
  const dispatch = useDispatch();
  const roles = useSelector(selectCareseekerRoles);

  // Check if the patient belongs to a different
  // clinic with a role in the current account.
  // If that is the case, dispatch a role switch.
  useEffect(() => {
    if (patient.id == careseekerId) return;
    const careseeker_id = patient.careseeker?.id;
    const country = patient.careseeker?.address?.country;
    const inRoles = roles?.some(
      (role) => role?.careseeker?.id === careseeker_id,
    );

    if (inRoles) {
      dispatch({
        type: "CARESEEKER_CHANGED",
        payload: {
          careseeker_id,
          careseeker_name: patient.careseeker?.name,
          country,
        },
      });
    }
  }, [patient.id, careseekerId]);

  return (
    <Result
      data={auction}
      getErrorLabel={(translations) => translations.patient.errorLoading}
      ErrorComponent={
        <RedirectButton
          label={translations.actions.backToDashboard}
          url="/dashboard/todo/active"
        />
      }
      id="patient-home-auction"
      queryProgress={queryProgress}
    >
      {(auction) => (
        <Outlet
          context={{
            auction,
            patient: auction.patient,
          }}
        />
      )}
    </Result>
  );
}

export default function ConnectedPatientHome() {
  const translations = useTranslations();
  const [searchParams] = useSearchParams();
  const { auctionId, patientId } = useIntParams<{
    auctionId: number | undefined;
    patientId: number;
  }>({
    patientId: -1,
  });
  const { Track } = useTracking<DeepPartial<BaseEventSchema>>({
    user_information: { patient_id: patientId, auction_id: auctionId },
  });

  const [queryProgress, patient] = useGetPatientQuery({
    patientId,
    decrypt: true,
  });

  return (
    <Track>
      <Result
        data={patient}
        getErrorLabel={(translations) => translations.patient.errorLoading}
        ErrorComponent={
          <RedirectButton
            label={translations.actions.backToDashboard}
            url="/dashboard/todo/active"
          />
        }
        id="patient-home-patient"
        queryProgress={queryProgress}
      >
        {(patient) => {
          if (!patient.auctions?.length) {
            return <NoAuctionsRedirectToDashboard patientId={patient?.id} />;
          }

          if (!auctionId) {
            const defaultAuction = patient?.auctions?.[0];
            return (
              <Navigate
                to={`/app/clinic/dashboard/patient/${patientId}/auction/${defaultAuction.id}/${PATIENT_TAB_SEARCH}?${searchParams}`}
                replace
              />
            );
          }

          const auction = patient.auctions?.find(({ id }) => id === auctionId);
          if (!auction) {
            return (
              <ErrorPage
                getErrorLabel={(translations) =>
                  translations.patient.errorLoadingUrlChanges
                }
                ErrorComponent={
                  <RedirectButton
                    label={translations.actions.backToDashboard}
                    url="/dashboard/todo/active"
                  />
                }
              />
            );
          }
          return (
            <PatientHomeContext patient={patient} auction={auction}>
              <PatientHome patient={patient} auctionId={auction.id} />
            </PatientHomeContext>
          );
        }}
      </Result>
    </Track>
  );
}

function NoAuctionsRedirectToDashboard({
  patientId,
}: {
  patientId: number | undefined;
}) {
  const account = useLoggedInAccount();
  const navigate = useNavigate();
  const notify = useNotification();
  const translations = useTranslations();

  useEffect(() => {
    console.error(
      `No auctions redirect - account ${account?.id} - patient ${patientId}`,
    );
    notify({
      type: "error",
      message: translations.patient.noAuctionsError,
    });
    setTimeout(() => {
      navigate("/app/clinic/dashboard");
    }, 0);
  }, [notify, translations, navigate]);

  return null;
}
